import { EventEmitter, Injectable } from '@angular/core';
import moment from 'moment-timezone';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinService {
    private subs: Subscription[] = [];

    private subject = new BehaviorSubject<boolean>(false)

    timer: Observable<number> = timer(0, 1000);
    spinDate!:moment.Moment

    constructor() { 
        this.spinDate = moment().add('+1', 'minutes')

        // this.subs.push(
        //     this.timer.subscribe((seconds) => {
        //         const d = moment()
        //         if(d.isAfter(this.spinDate)) {
        //             this.spinDate.add('+30', 'minutes')
        //             this.subject.next(true)
        //         }
        //     })
        // )
    }

    ngOnDestroy() {
        this.subs.map(s => s.unsubscribe());
    }

    public getData() {
        return this.subject.asObservable() 
    }

    public open() {
        this.subject.next(true);
    }

    public close() {
        this.subject.next(false);
    }

    public set(date:moment.Moment) {
        this.spinDate = date;
    }

    public add(amount?: moment.DurationInputArg1, unit?: moment.DurationInputArg2) {
        this.spinDate.add(amount, unit)
    }
}
