import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, Subject, catchError, map, of, pairwise, shareReplay, startWith, throwError } from 'rxjs';
import { ApiService } from '../../service/api.service';
import moment from 'moment-timezone';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SettingsService } from './settings.service';
import { SpinService } from '../../service/spin.service';

export interface currentUserInfo {
    idx?: number;
    uid?: string;
    nick?: string;
    role?: number;
    level?: number;
    profile_image?:string;
    Wallets?: any;
    memo?:number;
    sid?:string;
    exp?:number;
}

export const userRole =  {
    LEVEL1 : 1,
    LEVEL2: 2,
    LEVEL3: 4,
    LEVEL4: 8,
    LEVEL5: 16,
    LEVEL6: 32,
    LEVEL7: 64,
    LEVEL8: 128,
    LEVEL9: 256,
    LEVEL10: 512,
    LEVEL11: 1024,
    LEVEL12: 2048,
    LEVEL13: 5096,
    LEVEL14: 10192,
    LEVEL15: 20384,
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
    public currentUser:BehaviorSubject<currentUserInfo | null> = new BehaviorSubject<currentUserInfo | null>(null)
    
    
    hostname:string;
    constructor(
        private apiService:ApiService,
        private cookieService: CookieService,
        private jwtHelperService: JwtHelperService,
        private settingsService: SettingsService,
        private spinService: SpinService,
    ) {
        // this.hostname = window.location.hostname.replace('www.', '')
        this.hostname = window.location.hostname

        const currentUser =  this.cookieService.get('currentUser');
        if(currentUser) {
            this.currentUser.next(JSON.parse(currentUser));
        }
    }

    /**
     * [prev, current]
     */
    getPairwise(): Observable<any[]> { 
        return this.currentUser.pipe(
            startWith(null),
            pairwise(),
            shareReplay(1),
        ); 
    }

    clear(signOut:boolean = true) {
        this.cookieService.delete('currentUser', '/', this.hostname)
        this.cookieService.delete('access_token', '/', this.hostname)
        this.cookieService.delete('refresh_token', '/', this.hostname)
        this.cookieService.delete('twofa_token', '/', this.hostname)

        if(signOut) {
            sessionStorage.removeItem('REDIRECT');
            this.currentUser.next(null);
        }
    }

    set(data:any) {
        const currentUser = {  ...this.currentUser.getValue(), ...data };
        this.cookieService.set('currentUser', JSON.stringify(currentUser), 0, '/', this.hostname);
        this.currentUser.next(currentUser);  
    }

    signIn(uid: string, pwd: string, recaptcha:string) {
        const token = this.cookieService.get('currentToken')

        return this.apiService.post('/auth/signin', { uid: uid, pwd: pwd, 'cf-turnstile-response': recaptcha, token: token }, false).pipe(map(jsondata => {
            if(jsondata.success) {
                this.cookieService.delete('currentToken', '/', this.hostname)
                this.clear()

                const twofa_token = jsondata.data.users.twofa_token;
                if(twofa_token) {
                    this.cookieService.set('twofa_token', twofa_token, 0, '/', this.hostname);
                } else {
                    const access_token = jsondata.data.users.access_token;
                    const refresh_token = jsondata.data.users.refresh_token;

                    const data = jsondata.data.users;

                    delete data['access_token'];
                    delete data['refresh_token']

                    this.cookieService.set('currentUser', JSON.stringify(data), 0, '/', this.hostname);
                    this.cookieService.set('access_token', access_token, 0, '/', this.hostname);
                    this.cookieService.set('refresh_token', refresh_token, 0, '/', this.hostname);
    
                    this.currentUser.next(data);
                }

            } 
            
            return jsondata;
        }))
    }


    signOut() {
        this.clear(true);
        return this.apiService.get('/auth/signout', false).pipe(map(jsondata => {
            return jsondata;
        }),catchError((err) => {
            return throwError(() => err)
        }));
    }

    token(t: string) {
        this.clear()

        return this.apiService.post('/if/token', { t: t }, false).pipe(map(jsondata => {
            if(jsondata.success) {
                this.cookieService.set('currentToken', jsondata.data, 0, '/', this.hostname);
            } 

            return jsondata;
        }));
    }

    updateToken() {
        return this.apiService.post('/auth/update-token', {}, false).pipe(map(jsondata => {
            if(jsondata.success) {
                const access_token = jsondata.data.access_token;
                const refresh_token = jsondata.data.refresh_token;
        
                this.cookieService.set('access_token', access_token, 0, '/', this.hostname);
                this.cookieService.set('refresh_token', refresh_token, 0, '/', this.hostname);
            } 
            
            return jsondata;
        }))
    }

    totp(auth_code: string, recaptcha:string) {
        return this.apiService.post('/auth/signin-2fa', { auth_code: auth_code, 'cf-turnstile-response': recaptcha }, false).pipe(map(jsondata => {
            if(jsondata.success) {
                const access_token = jsondata.data.users.access_token;
                const refresh_token = jsondata.data.users.refresh_token;

                this.clear()
                const data = jsondata.data.users;
                delete data['access_token'];
                delete data['refresh_token']

                this.cookieService.set('currentUser', JSON.stringify(data), 0, '/', this.hostname);
                this.cookieService.set('access_token', access_token, 0, '/', this.hostname);
                this.cookieService.set('refresh_token', refresh_token, 0, '/', this.hostname);

                this.currentUser.next(data);

                this.settingsService.setValue(jsondata.data.settings)
            } 

            return jsondata;
        }));
    }

    authenticated() {
        return this.apiService.get("/auth/authenticated", false).pipe(map(jsondata => {
            if(jsondata.success) {
                this.set(jsondata.data.users);
                this.settingsService.setValue(jsondata.data.settings)
            } 

            return jsondata;
        }))
    }

    getTokenExpirationDate(): string | null {
        const access_token = this.cookieService.get('access_token')

        const decodedToken = this.jwtHelperService.decodeToken(access_token);
        if(!decodedToken || decodedToken.exp === undefined) { return null; }

        const d = moment.utc(decodedToken.exp, 'X').tz('Asia/Seoul')
        return d.format('YYYY-MM-DD HH:mm:ss')
    }

    update(options:any) {
        if(!this.currentUser.getValue()) return of(null);

        return this.apiService.post("/settings/update", options, false)
    }
}
